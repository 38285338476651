#AboutMe-wrapper {
    background-color: #CCEDD1;
    display: flex;
    padding-left: 15%;
    padding-top: 70px;
    padding-bottom: 15%;
    padding-right: 15%;
    /* min-height: 500px; */
    flex-direction: column;
}
#AboutMe-title {
    border-bottom: double 5px #000000;
    width: 110px;
    /* min-height: 100px; */
    margin-bottom: 30px;
}
#AboutMe-profile-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
}
.AboutMe-profile {
    display: flex;
}
.AboutMe-profile-title {
    min-width: 100px;
}
.AboutMe-profile-content::before {
    content: ': ';
}
.AboutMe-profile-content {
    font-size: large;
    font-weight: bold;
}