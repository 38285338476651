@import url('https://fonts.googleapis.com/css?family=M+PLUS+1p');
.HEAD-sticky-header {
    /* position: sticky;  */
    top: 0;
    width: 100%;
    background-color: #031D40;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px;
    /* background-position: 0%; */

    /* text-align: center; */
    /* color: #ffffff; */
    /* font-family: 'M PLUS 1p', sans-serif; */
    /* height: 50px; */
    
}

.HEAD-word {
    text-align: center;
    color: #ffffff;
    font-family: 'M PLUS 1p', sans-serif;
    height: 10;
}